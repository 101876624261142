import QRCodeGen from "../components/qrcode/QRCodeGen";
import QRCodeScanner from "../components/qrcode/QRCodeScanner";

function QRCode() {
  return (
    <section className="contact section" id="qr_code">
      <h2 className="section__title">QR code</h2>
      <span className="section__subtitle"> Simple example </span>

      <div className="contact__container container">
        {/* <img
          src={image}
          className="card-img-top"
          alt="..."
          style={{
            maxHeight: "350px",
            objectFit: "cover",
          }}
        />
        <Controller /> */}
        {/* <WebcamComponent /> */}

        <QRCodeGen
          data="-FrGd_1d4nGlpuq8AAAF"
          size={80}
          string_top="Scan me!"
          string_bottom=""
        />
        <QRCodeScanner />
      </div>
    </section>
  );
}

export default QRCode;
