import React from "react";
import Card from "react-bootstrap/Card";

import { Amplify } from "aws-amplify";
import awsmobile from "../aws-exports";
import "@aws-amplify/ui-react/styles.css";

import { PubSub, Auth, Hub } from "aws-amplify";
import { AWSIoTProvider } from "@aws-amplify/pubsub/lib/Providers";
import { CONNECTION_STATE_CHANGE, ConnectionState } from "@aws-amplify/pubsub";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

Amplify.configure(awsmobile);

// Apply plugin with configuration
Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: "eu-central-1",
    aws_pubsub_endpoint:
      "wss://aane1c46o0151-ats.iot.eu-central-1.amazonaws.com/mqtt",
  })
);

class Sensors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sensorMsg: '{"null": 0}',
    };
  }

  componentDidMount() {
    PubSub.subscribe("myTopic").subscribe({
      next: (data) => {
        try {
          // console.log("Inside");

          this.setState({ sensorMsg: data.value });
        } catch (error) {
          console.log("Error, are you sending the correct data?");
        }
      },
      error: (error) => console.error(error),
      close: () => console.log("Done"),
    });
    console.log("DidMountEnd");

    Hub.listen("pubsub", (data) => {
      const { payload } = data;
      if (payload.event === CONNECTION_STATE_CHANGE) {
        const connectionState = payload.data.connectionState;
        console.log(connectionState);
      }
    });
  }

  handleChange = (event, sensorName) => {
    let value = event.target.value;

    console.log(value);
    console.log(sensorName);

    // this.setState({ sensorMsg: value });

    console.log(this.state);

    PubSub.publish("myTopic", {
      [sensorName]: parseInt(value),
    });
  };

  render() {
    const { sensorMsg } = this.state;
    let sensorData = sensorMsg[this.props.name];

    console.log(sensorMsg);

    // if (sensorMsg[this.props.name]) {
    //   let sensorData = sensorMsg[this.props.name];
    //   // console.log(sensorMsg[this.props.name]);
    // } else {
    //   let sensorData = sensorMsg[this.props.name];
    // }

    return (
      <div className="Sensor">
        {/* <Card style={{ width: "18rem" }}> */}
        {/* <Card.Body> */}
        <Row className="p-3 justify-content-md-center">
          <Card.Title>{this.props.name}</Card.Title>
          <Card.Text>
            {sensorData}
            {/* {this.props.unit} */}
          </Card.Text>
        </Row>
        {/* </Card.Body> */}
        {/* </Card> */}
        <style jsx>
          {`
            .Sensor {
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
              transition: 0.3s;
            }

            .Sensor:hover {
              box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
            }
          `}
        </style>
        <>
          {/* <Form.Label>Range</Form.Label> */}
          <Form.Range
            onChange={(event) => this.handleChange(event, this.props.name)}
          />
        </>
      </div>
    );
  }
}

export default Sensors;
