import VideoCallApp from "../components/video_call/VideoCall";

function VideoCall() {
  return (
    <section className="contact section" id="video_call">
      <h2 className="section__title">Video Call</h2>
      <span className="section__subtitle">
        {" "}
        See through the eyes of your robot
      </span>

      {/* <div className="contact__container container"> */}
      <VideoCallApp />
      {/* </div> */}
    </section>
  );
}

export default VideoCall;
