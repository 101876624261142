import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { QrReader } from "react-qr-reader";
import { QRCodeReader } from "./QRCodeReader";
import { ViewFinder } from "./ViewFinder";
import { BsCheckLg, BsXLg } from "react-icons/bs";

interface IData {
  modul: string;
}

interface IDataQr {
  text: string;
  timestamp: number;
}

interface IResult {
  valid: boolean;
  modul: string;
}

export default function QRCodeScanner() {
  const navigate = useNavigate();

  const [qrCode, setQrCode] = useState<IDataQr>();
  const [loading, setLoading] = useState<boolean>(true);
  const [result, setResult] = useState<IResult>({ valid: false, modul: "" });

  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  useEffect(() => {
    if (qrCode) {
      setResult({ modul: qrCode.text, valid: true });
    } else {
      setResult({ modul: "QRCode not valid!", valid: false });
    }
  }, [qrCode]);

  // =============================

  const previewStyle = {
    height: "100%",
    width: "100%",
    // facingMode: { exact: "environment" }
  };

  return (
    <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 mt-5  w-full">
      <div className="container">
        <QRCodeReader setQrCode={setQrCode} />

        {/* <QrReader
          videoStyle={previewStyle}
          key="environment"
          ViewFinder={ViewFinder}
          onResult={(result, error) => {
            if (!!result) {
              setQrCode({
                text: result.getText(),
                timestamp: result.getTimestamp(),
              });
            }
            if (!!error) {
              console.log(error);
            }
          }}
          constraints={{ facingMode: "environment" }}
        /> */}
      </div>

      <div
        className={`flex flex-row border border-se-grey-6 rounded-md justify-center items-center w-full lg:w-full bg-se-grey-2  px-5 py-5`}
      >
        {result.valid ? (
          <BsCheckLg className="flex flex-row mr-5" color="#7ab97a" />
        ) : (
          <BsXLg className="flex flex-row  mr-5" color="#FF0000" />
        )}
        <pre className="flex flex-row font-bold text-xl">{result.modul}</pre>
      </div>
    </div>
  );
}
