import React from "react";
import QRCode from "react-qr-code";

interface IQRCodeGen {
  data: string;
  size: number;
  string_top: string;
  string_bottom: string;
}

export default function QRCodeGen(props: IQRCodeGen) {
  return (
    <div className="px-6 py-6 flex flex-col justify-center items-center ">
      <p className="font-mono"> {props.string_top}</p>
      <QRCode value={props.data} size={props.size} className="" />
      <span className="font-mono"> {props.string_bottom} </span>
    </div>
  );
}
