import React, { useState } from "react";
import axios from "axios";
import { Button as BootstrapButton } from "react-bootstrap";

const Settings: React.FC = () => {
  const [messages, setMessages] = useState<string[]>([]); // initialize state

  const handleServices = (action: string, desired_count: number) => {
    setMessages([]); // reset messages
    const serviceNames = ["Express5000", "fastapi_yolo5_8001"]; // replace with your service names

    serviceNames.forEach((service) => {
      axios
        .post(
          `${process.env.REACT_APP_API_GATEWAY_BASE_URL}${process.env.REACT_APP_API_GATEWAY_ENDPOINT_UPDATE_ECS_DESIRED_TASKS}`,
          {
            action: action,
            desired_count: desired_count,
            service: service,
          }
        )
        .then((response) => {
          console.log(response.data);
          setMessages((prevMessages) => [...prevMessages, `${response.data}.`]); // update state with response data
        })
        .catch((error) => {
          console.error(error);
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
          }
        });
    });
  };

  const buttonStyle = {
    width: "150px", // fixed width
    marginRight: "10px", // space to the right
  };

  return (
    <section className="contact section" id="object_detection">
      <h2 className="section__title">Settings</h2>
      {/* <span className="section__subtitle"> Example project </span> */}

      <div className="container">
        <p className="mt-4 mb-4">Backend cluster control:</p>
        <div className="m-5">
          <BootstrapButton
            style={buttonStyle}
            className="button "
            onClick={() => handleServices("update", 1)}
          >
            Start Services
          </BootstrapButton>
          <BootstrapButton
            style={buttonStyle}
            className="button "
            onClick={() => handleServices("update", 0)}
          >
            Stop Services
          </BootstrapButton>
        </div>
        {messages.map((msg, index) => (
          <p key={index}>{msg}</p>
        ))}{" "}
      </div>
    </section>
  );
};

export default Settings;
