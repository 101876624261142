import React from "react";
import Sensors from "../components/sensorData";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import image from "../images/DeltaRobot.jpg";

function DeltaRobot() {
  return (
    <section className="contact section" id="delta_robot">
      <h2 className="section__title">Delta robot</h2>
      {/* <span className="section__subtitle">with brain in the cloud</span> */}

      <div className="contact__container container">
        <img
          src={image}
          class="card-img-top"
          alt="..."
          style={{
            maxHeight: "350px",
            objectFit: "cover",
          }}
        />
        <Container className="p-4">
          <Row className="p-3 justify-content-md-center">
            <Col md="auto">
              {" "}
              <Sensors name="X-Coordinate" unit="°F" />{" "}
            </Col>
            <Col md="auto">
              {" "}
              <Sensors name="Y-Coordinate" unit="%" />{" "}
            </Col>
            <Col md="auto">
              {" "}
              <Sensors name="Z-Coordinate" unit="%" />{" "}
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default DeltaRobot;
