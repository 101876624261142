import React, { useState, useCallback } from "react";
import axios from "axios";
import Webcam from "react-webcam";
import { useDropzone, DropEvent, FileRejection } from "react-dropzone";
import { GridLoader, BeatLoader } from "react-spinners";
import Viewer from "../components/Viewer";

const WebcamComponent = () => <Webcam />;

interface MyDropzoneProps {}

interface MyFile extends File {
  path: string;
}

// define endpoint to call
// const API_URL_OBJECT_TO_IMG = `${process.env.REACT_APP_IMG_API_BASE_URL}${process.env.REACT_APP_IMG_API_ENDPOINT_OBJECT_TO_IMG}`;
// const API_URL_OBJECT_TO_IMG = `http://localhost:8088/yolo`;
const API_URL_OBJECT_TO_IMG = `${process.env.REACT_APP_IMG_ANALYTICS_URL_HTTP}${process.env.REACT_APP_IMG_ANALYTICS_ENDPOINT_YOLO}`;
console.log(API_URL_OBJECT_TO_IMG);

const MyDropzone: React.FC<MyDropzoneProps> = function () {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(
    (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      const file = acceptedFiles[0];
      const formData = new FormData();
      formData.append("file", file);
      setLoading(true); // Start loading
      axios
        .post(API_URL_OBJECT_TO_IMG, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "arraybuffer",
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: "image/jpeg" });
          const imageUrl = URL.createObjectURL(blob);
          setImageSrc(imageUrl);
          setLoading(false); // Stop loading
        })
        .catch((error) => {
          console.log(error);
          setLoading(false); // Stop loading
        });
    },
    []
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const files = (acceptedFiles as MyFile[]).map((file: MyFile) => {
    const preview = URL.createObjectURL(file);
    return (
      <li key={file.path}>
        {file.path} - {file.size} bytes
        <img
          src={preview}
          alt="Preview"
          style={{ width: "200px", height: "200px" }}
        />
      </li>
    );
  });

  return (
    <section className="">
      <div
        {...getRootProps({ className: "dropzone" })}
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          borderWidth: 2,
          borderRadius: 2,
          borderColor: "#eeeeee",
          borderStyle: "dashed",
          backgroundColor: "#fafafa",
          color: "#bdbdbd",
          outline: "none",
          transition: "border .24s ease-in-out",
        }}
      >
        <input {...getInputProps()} />
        <p>Drag 'n' drop file here, or click to select file</p>
      </div>
      {loading && (
        <div
          style={{ display: "flex", justifyContent: "center", padding: "20px" }}
        >
          <GridLoader color="#8d5ce0" />
        </div>
      )}{" "}
      <aside className="mt-10">
        {/* <ul>{files}</ul> */}
        {imageSrc && <img src={imageSrc} alt="Object detection result" />}
      </aside>
    </section>
  );
};

function ObjectDetection() {
  return (
    <section className="contact section" id="object_detection">
      <h2 className="section__title">Object detection</h2>
      <span className="section__subtitle"> Example project </span>

      <div className="contact__container container">
        <MyDropzone />
        {/* <WebcamComponent /> */}
        <Viewer />
      </div>
    </section>
  );
}

export default ObjectDetection;
