import React, { useEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Peer from "simple-peer";
import io from "socket.io-client";
import { Button as BootstrapButton } from "react-bootstrap";
import Button from "@material-ui/core/Button";
// import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PhoneIcon from "@material-ui/icons/Phone";
import CallEndIcon from "@mui/icons-material/CallEnd";
import MicOffIcon from "@mui/icons-material/MicOff";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import CircularProgress from "@mui/material/CircularProgress";

// AWS
import { Auth } from "aws-amplify";

// own packages
import "./myapp.css";
import QRCodeGen from "../qrcode/QRCodeGen";
import QRCodeScanner from "../qrcode/QRCodeScanner2";
import { useAudio } from "../useAudio";

// path to audio files
const path_to_audio_files = "../../audio/";

// const socket = io("http://localhost:5000/");
const socket = io("https://express.aidobotics.ai/");
// const socket = io("http://3.75.189.178:5000/"); // Public IP address of container running on ECS https://eu-central-1.console.aws.amazon.com/ecs/v2/clusters/myAidoboticsApp_BE_Cluster/tasks/1ca0f444dc454d6b894ff58c7fd41fbc/networking?region=eu-central-1
// const socket = io(process.env.REACT_APP_SOCKET_URL as string);
// console.log(process.env.REACT_APP_SOCKET_URL);

console.log("Using the following socket:");
console.log(socket);

function VideoCallApp() {
  const [clientType, setClientType] = useState<string>(""); // "call sender" or "call receiver"
  const [me, setMe] = useState<string>("");
  const [stream, setStream] = useState<MediaStream | undefined>();
  const [sendingCall, setSendingCall] = useState<boolean>(false);
  const [receivingCall, setReceivingCall] = useState<boolean>(false);
  const [caller, setCaller] = useState<string>("");
  const [callerSignal, setCallerSignal] = useState<any>();
  const [callAccepted, setCallAccepted] = useState<boolean>(false);
  const [idToCall, setIdToCall] = useState<string>("");
  const [callEnded, setCallEnded] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const myVideo = useRef<HTMLVideoElement | null>(null);
  const userVideo = useRef<HTMLVideoElement | null>(null);
  const connectionRef = useRef<any>();

  const [showScanner, setShowScanner] = useState(false);

  const [userEmail, setUserEmail] = useState("");

  // audio constants
  const {
    start: startAcceptSound,
    // stop: stopAcceptSound,
    // toggle: toggleAcceptSound,
    // playing: playingAcceptSound,
  } = useAudio(`${path_to_audio_files}accept.mp3`);

  const {
    start: startRingingSound,
    stop: stopRingingSound,
    // toggle: toggleRingingSound,
    // playing: playingRingingSound,
  } = useAudio(`${path_to_audio_files}ringing.mp3`);

  const {
    start: startCallingSound,
    stop: stopCallingSound,
    // toggle: toggleCallingSound,
    // playing: playingCallingSound,
  } = useAudio(`${path_to_audio_files}calling.mp3`);

  const [useFrontCamera, setUseFrontCamera] = useState(true);

  const fetchUserEmail = async () => {
    try {
      const userInfo = await Auth.currentAuthenticatedUser();
      setUserEmail(userInfo.attributes.email);
      setName(userInfo.attributes.email);
    } catch (error) {
      console.error("Error fetching user email", error);
    }
  };

  useEffect(() => {
    // hack to trigger a "me" event providing the ID
    socket.disconnect();
    socket.connect();

    socket.on("me", (id: string) => {
      setMe(id);
      console.log("Connected to server. My ID is:", id);
    });

    socket.on("callEnded", () => {
      // code to execute when the call is ended
      console.log("Received: callEnded event");
      leaveCall();
    });

    fetchUserEmail();
  }, []);

  useEffect(() => {
    socket.on(
      "callUser",
      (data: { from: string; name: string; signal: any }) => {
        console.log(
          "Receiving call from: " + data.from + " (" + data.name + ")"
        );
        setReceivingCall(true);
        startRingingSound();
        setCaller(data.from);
        setName(data.name);
        setCallerSignal(data.signal);
      }
    );
  }, [startRingingSound]);

  useEffect(() => {
    console.log("Starting useEffect");
    navigator.mediaDevices
      .getUserMedia({
        // video: true,

        video: {
          facingMode: useFrontCamera ? "user" : "environment",
        },

        // video: {
        //   // facingMode: "user", // use the front camera
        //   facingMode: { exact: "environment" }, // use the rear camera
        // },

        audio: true,
      })
      .then((currentStream) => {
        setStream(currentStream);
        console.log("myVideo:");
        console.log(myVideo);
        if (myVideo.current) {
          myVideo.current.srcObject = currentStream;
          console.log(currentStream);
        }
      })
      .catch((error) => {
        console.error("Error accessing media devices.", error);
      });
  }, [useFrontCamera]); // added useFrontCamera as a dependency

  const callUser = (id: string) => {
    // client = Call sender

    setClientType("Call sender");

    if (id !== "") {
      console.log("Calling user with ID: " + id);
      setSendingCall(true);
      startCallingSound();

      const peer = new Peer({
        initiator: true,
        trickle: false,
        stream: stream,
      });

      peer.on("signal", (data: any) => {
        socket.emit("callUser", {
          userToCall: id,
          signalData: data,
          from: me,
          name: name,
        });
      });

      peer.on("stream", (stream: MediaStream) => {
        if (userVideo.current) {
          userVideo.current.srcObject = stream;
        }
      });

      socket.on("callAccepted", (signal: any) => {
        console.log("Call accepted.");
        setCallAccepted(true);
        stopCallingSound();

        // check (remove later)
        // printStates();

        peer.signal(signal);
      });

      connectionRef.current = peer;
    } else {
      alert("Please provide an ID to call.");
    }
  };

  const answerCall = () => {
    // client = Call receiver

    setClientType("Call receiver");

    console.log("Call answered.");
    setCallAccepted(true);
    stopRingingSound();
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream: stream,
    });

    peer.on("signal", (data: any) => {
      socket.emit("answerCall", { signal: data, to: caller });
    });

    peer.on("stream", (stream: MediaStream) => {
      if (userVideo.current) {
        userVideo.current.srcObject = stream;
      }
    });

    peer.signal(callerSignal);
    connectionRef.current = peer;
  };

  const leaveCall = () => {
    let id;

    if (clientType === "Call sender") {
      id = idToCall;
    } else {
      id = caller;
    }

    // inform server to end call
    console.log("End call with: " + id);
    socket.emit("endCall", { otherClientId: id });

    // internally process call to end
    endCall();

    if (connectionRef.current) {
      try {
        // connectionRef.current.destroy();
      } catch (error) {
        console.error("Failed to destroy peer connection", error);
      }
    }
  };

  const endCall = () => {
    console.log("Ending call.");

    // reset state variables
    // setCallEnded(true);
    setReceivingCall(false);
    setSendingCall(false);
    setCallAccepted(false);

    // printStates();

    // reset audio
    stopCallingSound();
    stopRingingSound();
  };

  const printStates = () => {
    console.log("Printing states ...");
    console.log("sendingCall: " + sendingCall);
    console.log("receivingCall: " + receivingCall);
    console.log("callAccepted: " + callAccepted);
    console.log("callEnded: " + callEnded);
    console.log("showScanner: " + showScanner);
  };

  const openQRScanner = () => {
    // console.log("Scan button pressed!");
    setShowScanner(true);
  };

  const handleScan = (data: string) => {
    startAcceptSound();
    setIdToCall(data);
    setShowScanner(false);
  };

  const closeQRScanner = () => {
    setShowScanner(false);
  };

  // //
  // Rendering
  // //

  let ComponentToRender;

  if (showScanner) {
    ComponentToRender = (
      <QRScannerComponent onScan={handleScan} onClose={closeQRScanner} />
    );
  } else if (sendingCall && !callAccepted) {
    ComponentToRender = (
      <div className="container">
        <div className="myId">
          <h1 style={{ textAlign: "center" }}>Calling ID: {idToCall}</h1>
          <div className="myId">
            <CircularProgress />
          </div>
          <div className="myId">
            <EndCallButton onClick={leaveCall}></EndCallButton>
          </div>
        </div>
      </div>
    );
  } else if (receivingCall && !callAccepted) {
    ComponentToRender = (
      <div className="myId">
        <div className="caller">
          <h1>{name}</h1>
          <h1 style={{ color: "grey", fontSize: "small" }}>
            ... is calling you ...
          </h1>
          <BootstrapButton
            className="circular-button circular-button-call"
            onClick={answerCall}
          >
            <PhoneIcon />
          </BootstrapButton>

          <EndCallButton onClick={leaveCall}></EndCallButton>
        </div>
      </div>
    );
  } else if (callAccepted && !callEnded) {
    ComponentToRender = (
      <div className="container">
        <div className="myId">
          <VideoComponent
            stream={stream}
            myVideo={myVideo}
            userVideo={userVideo}
            callAccepted={callAccepted}
            callEnded={callEnded}
            setUseFrontCamera={setUseFrontCamera}
          />
          <div className="button-row">
            <CameraSwitchButton
              onClick={() => {
                setUseFrontCamera((prev) => !prev);
              }}
            ></CameraSwitchButton>

            <EndCallButton onClick={leaveCall}></EndCallButton>

            <BootstrapButton
              className="circular-button"
              // onClick={() => {
              //   setUseFrontCamera((prev) => !prev);
              // }}
            >
              <MicOffIcon />
            </BootstrapButton>
          </div>
        </div>
      </div>
    );
    // } else if (callEnded) {
    //   console.log("callEnded");
    //   ComponentToRender = (
    //     <div className="container">
    //       <h1>Call ended</h1>
    //     </div>
    //   );
  } else {
    ComponentToRender = (
      <CallControls
        name={name}
        setName={setName}
        me={me}
        openQRScanner={openQRScanner}
        idToCall={idToCall}
        setIdToCall={setIdToCall}
        callAccepted={callAccepted}
        callEnded={callEnded}
        leaveCall={leaveCall}
        callUser={callUser}
        receivingCall={receivingCall}
        answerCall={answerCall}
      />
    );
  }

  return (
    <>
      <div className="container">{ComponentToRender}</div>
    </>
  );
}

export default VideoCallApp;

// //
// EndCallButton
// //

type EndCallButtonProps = {
  onClick: () => void;
};

const EndCallButton: React.FC<EndCallButtonProps> = ({ onClick }) => {
  return (
    <BootstrapButton
      className="circular-button circular-button-end-call"
      onClick={onClick}
    >
      <CallEndIcon />
    </BootstrapButton>
  );
};

// //
// CameraSwitchButton
// //

type CameraSwitchButtonProps = {
  onClick: () => void;
};

const CameraSwitchButton: React.FC<CameraSwitchButtonProps> = ({ onClick }) => {
  return (
    <BootstrapButton className="circular-button" onClick={onClick}>
      <CameraswitchIcon />
    </BootstrapButton>
  );
};

// //
// CallControls
// //

type CallControlsProps = {
  name: string;
  setName: (name: string) => void;
  me: string;
  openQRScanner: () => void;
  idToCall: string;
  setIdToCall: (id: string) => void;
  callAccepted: boolean;
  callEnded: boolean;
  leaveCall: () => void;
  callUser: (id: string) => void;
  receivingCall: boolean;
  answerCall: () => void;
};

const CallControls: React.FC<CallControlsProps> = ({
  name,
  setName,
  me,
  openQRScanner,
  idToCall,
  setIdToCall,
  callAccepted,
  callEnded,
  leaveCall,
  callUser,
  receivingCall,
  answerCall,
}) => (
  <div className="myId">
    {/* <TextField
      id="filled-basic"
      label="My name"
      variant="filled"
      value={name}
      onChange={(e) => setName(e.target.value)}
    /> */}

    <div className="myId">
      <CopyToClipboard text={me}>
        <BootstrapButton className="button">
          <AssignmentIcon />
          Copy ID
        </BootstrapButton>
      </CopyToClipboard>

      <QRCodeGen
        data={me}
        size={200}
        string_top={`My ID: ${me}`}
        string_bottom=""
      />

      <BootstrapButton className="button" onClick={openQRScanner}>
        <QrCodeScannerIcon />
        SCAN ID
      </BootstrapButton>
    </div>

    <div className="call-container">
      <TextField
        id="filled-basic"
        label="ID to call"
        variant="filled"
        value={idToCall}
        onChange={(e) => setIdToCall(e.target.value)}
      />

      <div className="call-button">
        <BootstrapButton
          className="circular-button circular-button-call"
          onClick={() => callUser(idToCall)}
        >
          <PhoneIcon />
        </BootstrapButton>
        {/* {idToCall} */}
      </div>
    </div>
  </div>
);

// //
// QRScannerComponent
// //

type QRScannerComponentProps = {
  onScan: (data: string) => void; // replace 'string' with the actual type of 'data' if it's not a string
  onClose: () => void;
};

const QRScannerComponent: React.FC<QRScannerComponentProps> = ({
  onScan,
  onClose,
}) => {
  return (
    <div className="flex justify-center">
      <div className="w-80">
        <QRCodeScanner onScan={onScan} />
        <div className="flex justify-center m-5">
          <Button
            variant="contained"
            color="primary"
            startIcon={<HighlightOffIcon fontSize="large" />}
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

// //
// VideoComponent
// /

type VideoComponentProps = {
  stream: MediaStream | undefined;
  myVideo: React.RefObject<HTMLVideoElement>;
  userVideo: React.RefObject<HTMLVideoElement>;
  callAccepted: boolean;
  callEnded: boolean;
  setUseFrontCamera: React.Dispatch<React.SetStateAction<boolean>>;
};

const VideoComponent: React.FC<VideoComponentProps> = ({
  stream,
  myVideo,
  userVideo,
  callAccepted,
  callEnded,
  setUseFrontCamera,
}) => {
  return (
    <div
      className=""
      style={{ position: "relative", width: "300px", height: "300px" }}
    >
      <div
        // other video
        className="video"
        style={{ position: "absolute", width: "100%", height: "100%" }}
      >
        {callAccepted && !callEnded ? (
          <video
            playsInline
            ref={userVideo}
            autoPlay
            style={{
              width: "100%",
              height: "100%",
              border: "3px solid #000", // Add border here
              background: "#000",
            }}
          />
        ) : null}
      </div>
      <div
        // own video
        className="video"
        style={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
          width: "100px",
          height: "100px",
        }}
      >
        {stream && (
          <video
            playsInline
            muted
            ref={myVideo}
            autoPlay
            style={{ width: "100%", height: "100%" }}
          />
        )}
      </div>
    </div>
  );
};
