import { useState, useEffect, useCallback } from "react";

export const useAudio = (url: string) => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  const start = () => {
    setPlaying(true);
    audio.currentTime = 0;
    audio.play();
  };

  const stop = useCallback(() => {
    setPlaying(false);
    audio.pause();
    audio.currentTime = 0;
  }, [audio]);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing, audio]);

  useEffect(() => {
    audio.addEventListener("ended", stop);
    return () => {
      audio.removeEventListener("ended", stop);
    };
  }, [stop, audio]);

  return { start, stop, toggle, playing };
};
