import React from "react";
import Sensors from "../components/sensorData";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import image from "../images/Humanoid_with_brain_in_the_cloud.png";

function Humanoid() {
  return (
    <section className="contact section" id="humanoid">
      <h2 className="section__title">Humanoid</h2>
      <span className="section__subtitle">with brain in the cloud</span>

      <div className="contact__container container">
        {/* Tailwind example */}
        <div class="flex justify-center">
          <div class="bg-green-500 text-white text-xs font-medium mr-1 px-1.5 py-0.5 my-3 rounded">
            Online
          </div>
          <div class="bg-red-500 text-white text-xs font-medium mr-1 px-1.5 py-0.5 my-3 rounded">
            Attention!
          </div>
        </div>

        <img
          src={image}
          class="card-img-top"
          alt="..."
          style={{
            maxHeight: "650px",
            objectFit: "cover",
          }}
        />

        <h2 className="section__title">Try out below:</h2>

        <Container className="p-4">
          <Row className="p-3 justify-content-md-center">
            <Col md="auto">
              {" "}
              <Sensors name="Motor #1" unit="°F" />{" "}
            </Col>
            <Col md="auto">
              {" "}
              <Sensors name="Motor #2" unit="%" />{" "}
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default Humanoid;
