import "./App.css";
import { Amplify, Auth } from "aws-amplify";
import awsmobile from "./aws-exports";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import React, { Fragment, Suspense } from "react";
import Navbar from "./components/navbar/Navbar";
import Humanoid from "./pages/Humanoid";
import DeltaRobot from "./pages/DeltaRobot";
import Smars from "./pages/Smars";
import ObjectDetection from "./pages/ObjectDetection";
import QRCode from "./pages/QRCode";
import VideoCall from "./pages/VideoCall";
import Settings from "./pages/Settings";
// import VideoStreaming from "./pages/";

import { HashRouter, Route, Routes } from "react-router-dom";
import { CognitoUser } from "amazon-cognito-identity-js";

Amplify.configure(awsmobile);

Auth.currentCredentials().then((creds) => console.log(creds));
Auth.currentCredentials().then((info) => {
  const cognitoIdentityId = info.identityId;
  console.log("cognitoIdentityId:");
  console.log(cognitoIdentityId);
});

interface AppProps {
  signOut?: () => void;
  user?: CognitoUser;
}

function App({ signOut, user }: AppProps) {
  return (
    <Fragment>
      <main className="main">
        <Suspense fallback={<div>Loading...</div>}>
          <HashRouter>
            <Navbar />
            <Routes>
              <Route path="/" element={<Humanoid />} />
              <Route path="/smars" element={<Smars />} />
              <Route path="/delta_robot" element={<DeltaRobot />} />
              <Route path="/object_detection" element={<ObjectDetection />} />
              <Route path="/qr_code" element={<QRCode />} />
              <Route path="/video_call" element={<VideoCall />} />
              <Route path="/settings" element={<Settings />} />
              {/* <Route path="/video_streaming" element={<VideoStreaming />} /> */}
            </Routes>
          </HashRouter>
        </Suspense>
      </main>
      <div className="App"></div>
    </Fragment>
  );
}

export default withAuthenticator(App);
