import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";

import { Auth } from "aws-amplify";
import SignOutButton from "../signOut";

import "../../App.css";
import useWindowResize from "../../hooks/useWindowResize";
import Logo from "../../images/logo.png";

import {
  Avatar,
  Dropdown,
  DropdownDivider,
  DropdownHeader,
  DropdownItem,
} from "flowbite-react";

function getCSSVariable(varName) {
  return getComputedStyle(document.documentElement).getPropertyValue(varName);
}

function Navbar() {
  const { windowWidth } = useWindowResize();

  let NavItemLeft;
  let NavItemCenter;
  let NavItemRight;

  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    fetchUserEmail();
  }, []);

  const fetchUserEmail = async () => {
    try {
      const userInfo = await Auth.currentAuthenticatedUser();
      setUserEmail(userInfo.attributes.email);
    } catch (error) {
      console.error("Error fetching user email", error);
    }
  };

  // const navigate = useNavigate();

  //------------------------------------START -> MENU SHOW & HIDE-------------------------
  const [navBarState, setNavBarState] = useState(false);

  /*===== MENU SATE CONTROL ======*/
  const handleClickNav = () => {
    setNavBarState(!navBarState);
  };

  /*==================== SCROLL SECTIONS ACTIVE LINK ====================*/
  // useEffect(() => {
  //   const sections = document.querySelectorAll("section[id]");

  //   function scrollActive() {
  //     const scrollY = window.pageYOffset;

  //     sections.forEach((current) => {
  //       const sectionHeight = current.offsetHeight;
  //       const sectionTop = current.offsetTop - 50;
  //       const sectionId = current.getAttribute("id");
  //       console.log(sectionId);

  //       if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
  //         document
  //           .querySelector(".nav__menu a[href*=" + sectionId + "]")
  //           .classList.add("active-link");
  //       } else {
  //         document
  //           .querySelector(".nav__menu a[href*=" + sectionId + "]")
  //           .classList.remove("active-link");
  //       }
  //     });
  //   }
  //   window.addEventListener("scroll", scrollActive);
  // });

  /*==================== DARK LIGHT THEME ====================*/
  const darkTheme = "dark-theme";
  const currentTheme =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [theme, setTheme] = useState(!currentTheme);

  const handleTheme = () => {
    setTheme(!theme);
  };

  if (theme) {
    document.body.classList.remove(darkTheme);
  } else {
    document.body.classList.add(darkTheme);
  }

  /*==================== HAMBURGER MENU ====================*/

  function UserDropdown({ userEmail }) {
    const navigate = useNavigate();

    return (
      <div className="">
        <Dropdown
          label={<Avatar placeholderInitials="RR" rounded />}
          arrowIcon={false}
          inline
        >
          <DropdownHeader>
            <span className="block text-sm">Logged in as</span>
            <span className="block truncate text-sm font-medium">
              {userEmail}
            </span>
          </DropdownHeader>
          <DropdownItem onClick={() => alert("Profile clicked")}>
            Profile
          </DropdownItem>
          <DropdownItem onClick={() => navigate("/settings")}>
            Settings
          </DropdownItem>
          <DropdownDivider />
          <DropdownItem>
            <SignOutButton />
          </DropdownItem>
        </Dropdown>
      </div>
    );
  }

  const navItems = [
    { path: "/smars", label: "Wheeler" },
    { path: "/object_detection", label: "Object Detection" },
    { path: "/qr_code", label: "QRCode" },
    { path: "/", label: "Humanoid" },
    { path: "delta_robot", label: "Delta Robot" },
    { path: "video_call", label: "Video Call" },
    // { path: "video_streaming", label: "Video Streaming" },
  ];

  const NavList = ({ windowWidth }) => (
    <ul className="nav__list grid">
      <Nav
        className={
          windowWidth >= getCSSVariable("--screen-width-medium-device")
            ? "nav__list__large_screen"
            : "nav__list__small_screen"
        }
        defaultActiveKey="/home"
        as="ul"
      >
        {navItems.map((item, index) => (
          <Nav.Item as="li" key={index}>
            <Nav.Link as={Link} to={item.path} onClick={handleClickNav}>
              {item.label}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
    </ul>
  );

  const HamburgerIcon = () => (
    <div>
      <svg
        className={"iconStyle"}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path d="M4 6h16M4 12h16M4 18h16"></path>
      </svg>
    </div>
  );

  const HamburgerMenu = () => (
    <div>
      <Dropdown label={<HamburgerIcon />} arrowIcon={false} inline>
        {navItems.map((item, index) => (
          <DropdownItem>
            <Nav.Item as="li" key={index}>
              <Nav.Link>
                <Link to={item.path} onClick={handleClickNav}>
                  {item.label}
                </Link>
              </Nav.Link>
            </Nav.Item>
          </DropdownItem>
        ))}
      </Dropdown>
    </div>
  );

  const ExtendedMenu = () => (
    <div className="nav__menu" id="nav-menu">
      <NavList windowWidth={windowWidth} />
    </div>
  );

  function LogoElement() {
    return (
      <a href="/" className="nav__logo">
        <img src={Logo} alt="" className="nav__img" />
      </a>
    );
  }

  // alert(getCSSVariable("--screen-width-medium-device"));
  if (windowWidth >= getCSSVariable("--screen-width-medium-device")) {
    NavItemLeft = <LogoElement />;
    NavItemCenter = <ExtendedMenu />;
    NavItemRight = <UserDropdown userEmail={userEmail} />;
  } else {
    NavItemLeft = <HamburgerMenu />;
    NavItemCenter = <LogoElement />;
    NavItemRight = <UserDropdown userEmail={userEmail} />;
  }

  /*==================================================*/

  return (
    <header className="header" id="header">
      <nav className="nav container">
        {NavItemLeft}
        {NavItemCenter}
        {NavItemRight}
      </nav>
    </header>
  );
}

export default Navbar;
