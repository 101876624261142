import React from "react";
import { Auth } from "aws-amplify";

function SignOutButton() {
  const signOut = (e) => {
    e.preventDefault();
    Auth.signOut().then(() => {
      window.location.reload(false);
    });
  };

  return (
    <div className="">
      {/* <a>Hello {Auth.user.username}</a> */}
      <button onClick={signOut}>Sign out</button>
    </div>
  );
}

export default SignOutButton;
