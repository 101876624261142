import React, { useEffect, useState, useRef } from "react";

// import Amplify from 'aws-amplify'
// https://theskenengineering.com/building-a-react-js-app-with-aws-iot/
import { Amplify } from "aws-amplify";
import awsmobile from "../aws-exports";
import "@aws-amplify/ui-react/styles.css";

import { PubSub, Auth, Hub } from "aws-amplify";
import { AWSIoTProvider } from "@aws-amplify/pubsub/lib/Providers";
import { CONNECTION_STATE_CHANGE, ConnectionState } from "@aws-amplify/pubsub";

import { Joystick, JoystickShape } from "react-joystick-component";

Amplify.configure(awsmobile);

// Apply plugin with configuration
Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: "eu-central-1",
    aws_pubsub_endpoint:
      "wss://aane1c46o0151-ats.iot.eu-central-1.amazonaws.com/mqtt",
  })
);

interface IJoystickStatus {
  x: number;
  y: number;
  type: string | null;
  direction: string | null;
  distance: number | null;
}

export default function Controller() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [status, setStatus] = useState<string>("offline");
  const [robotId, setRobotId] = useState<string>("1");
  const [joystickStatus, setJoystickStatus] = useState<IJoystickStatus>({
    x: 0,
    y: 0,
    type: "stop",
    direction: "IDLE",
    distance: 0.0,
  });

  useEffect(() => {}, [joystickStatus]);

  const publish = async (d: IJoystickStatus) => {
    await PubSub.publish("ros2/cmd", {
      controller: {
        move: d.x,
        turn: -d.y,
      },
    });

    setJoystickStatus({
      x: d.x,
      y: -d.y,
      type: d.type,
      direction: d.direction,
      distance: 0.0,
    });
  };

  // ----------------------------------
  const publishLightOn = async () => {
    await PubSub.publish(`light_on/${robotId}`, {});
  };

  // ----------------------------------
  const publishLightOff = async () => {
    await PubSub.publish(`light_off/${robotId}`, {});
  };

  return (
    <div className="xl:w-full md:w-full sm:w-full w-full">
      <div className="grid gap-4 mb-5 md:grid-cols-1 sm:grid-cols-1 xl:grid-cols-1 grid-cols-1">
        <div className="flex-col rounded-xl border border-gray-500 bg-gray-200 p-5 shadow-xl w-full">
          {status === "online" ? (
            <span
              className={`bg-green-900 text-white text-xs font-medium mr-1 px-1.5 py-0.5 my-3 rounded`}
            >
              {status}
            </span>
          ) : (
            <span
              className={`bg-red-900 text-white text-xs font-medium mr-1 px-1.5 py-0.5 my-3 rounded`}
            >
              {status}
            </span>
          )}
          <span
            className={`bg-red-900 text-white text-xs font-medium mr-1 px-1.5 py-0.5 my-3 rounded`}
          >
            x: {joystickStatus.x}
          </span>
          <span
            className={`bg-red-900 text-white text-xs font-medium mr-1 px-1.5 py-0.5 my-3 rounded`}
          >
            y: {joystickStatus.y}
          </span>
          <span
            className={`bg-red-900 text-white text-xs font-medium mr-1 px-1.5 py-0.5 my-3 rounded`}
          >
            {joystickStatus.direction}
          </span>
          <span
            className={`bg-red-900 text-white text-xs font-medium mr-1 px-1.5 py-0.5 my-3 rounded`}
          >
            {joystickStatus.type}
          </span>
          <h2 className="my-4 text-xl font-extrabold leading-none tracking-tight text-gray-700 ">
            Controller
          </h2>

          <div className="flex flex-row gap-5">
            <Joystick
              size={100}
              sticky={false}
              baseColor="gray"
              throttle={40}
              minDistance={10}
              stickColor="#800080"
              move={(d: any) => publish(d)}
              stop={(d: any) =>
                publish({
                  x: 0,
                  y: 0,
                  type: "stop",
                  direction: "IDLE",
                  distance: 0.0,
                })
              }
              baseShape={JoystickShape.Square}
            />

            <button
              className="bg-purple-800 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-xl"
              onClick={() => publishLightOn()}
            >
              Lights On
            </button>

            <button
              className="bg-purple-300 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-xl"
              onClick={() => publishLightOff()}
            >
              Lights Off
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
