import Controller from "../components/controller";
import image from "../images/smars.jpg";

function Smars() {
  return (
    <section className="contact section" id="smars">
      <h2 className="section__title">SMARS”</h2>
      <span className="section__subtitle">
        {" "}
        Screwless/Screwed Modular Assemblable Robotic System
      </span>

      <div className="contact__container container">
        <img
          src={image}
          className="card-img-top"
          alt="..."
          style={{
            maxHeight: "350px",
            objectFit: "cover",
          }}
        />
        <Controller />
      </div>
    </section>
  );
}

export default Smars;
