import React, { Component } from "react";
import QrReader from "react-qr-scanner";
import Button from "@material-ui/core/Button";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";

interface QRCodeScannerProps {
  onScan: (data: string) => void;
}

interface QRCodeScannerState {
  delay: number;
  result: string;
  scanning: boolean;
  useFrontCamera: boolean;
}

class QRCodeScanner extends Component<QRCodeScannerProps, QRCodeScannerState> {
  constructor(props: QRCodeScannerProps) {
    super(props);
    this.state = {
      delay: 100,
      result: "No result",
      scanning: true,
      useFrontCamera: false,
    };

    this.handleScan = this.handleScan.bind(this);
    this.switchCamera = this.switchCamera.bind(this);
  }
  handleScan(data: any) {
    if (data) {
      this.setState({ result: data.text, scanning: false });
      this.props.onScan(data.text);
    }
  }
  handleError(err: any) {
    console.error(err);
  }

  switchCamera() {
    this.setState((prevState) => ({
      useFrontCamera: !prevState.useFrontCamera,
    })); // Switch camera
  }

  render() {
    const previewStyle = {
      height: "100%",
      width: "100%",
    };

    return (
      <div className="">
        <div className="grid justify-content">
          <Button
            variant="contained"
            color="primary"
            startIcon={<CameraswitchIcon fontSize="large" />}
            onClick={this.switchCamera}
          >
            Switch Camera
          </Button>
        </div>

        {this.state.scanning && (
          <div style={{ position: "relative" }}>
            <QrReader
              // videoStyle={previewStyle}
              delay={this.state.delay}
              style={previewStyle}
              onError={this.handleError}
              onScan={this.handleScan}
              constraints={{
                audio: false,
                video: {
                  facingMode: this.state.useFrontCamera
                    ? "user"
                    : "environment",
                },
              }}
            />
            <div
              style={{
                top: 0,
                left: 0,
                zIndex: 1,
                boxSizing: "border-box",
                border: "30px solid rgba(0, 0, 0, 0.5)",
                position: "absolute",
                width: "100%",
                height: "100%",
              }}
            />
          </div>
        )}
        {/* <h1>{this.state.result}</h1> */}
      </div>
    );
  }
}

export default QRCodeScanner;
