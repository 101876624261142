import React from "react";
import { QrReader } from "react-qr-reader";
import { ViewFinder } from "./ViewFinder";

interface IDataQr {
  text: string;
  timestamp: number;
}

interface QRCodeReaderProps {
  setQrCode: (data: IDataQr) => void;
}

export const QRCodeReader: React.FC<QRCodeReaderProps> = ({ setQrCode }) => {
  const previewStyle = {
    height: "100%",
    width: "100%",
  };

  return (
    <QrReader
      videoStyle={previewStyle}
      key="environment"
      ViewFinder={ViewFinder}
      onResult={(result, error) => {
        if (!!result) {
          setQrCode({
            text: result.getText(),
            timestamp: result.getTimestamp(),
          });
        }
        if (!!error) {
          console.log(error);
        }
      }}
      constraints={{ facingMode: "environment" }}
    />
  );
};
